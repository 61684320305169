import React, { useState } from 'react';

const SkillsSection = () => {
  const [activeTab, setActiveTab] = useState('skills');
  
  const skills = [
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M12 18.5V19.5M7 14.5H17V9C17 8.07 16.7 7.25 16.1 6.65C15.5 6.05 14.7 5.75 13.75 5.75H10.25C9.3 5.75 8.5 6.05 7.9 6.65C7.3 7.25 7 8.07 7 9V14.5Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.5 5.75V4.25C10.5 4.05 10.58 3.86 10.73 3.73C10.86 3.58 11.05 3.5 11.25 3.5H12.75C12.95 3.5 13.14 3.58 13.27 3.73C13.42 3.86 13.5 4.05 13.5 4.25V5.75" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17 14.5C18.1 14.5 19.12 14.92 19.87 15.67C20.62 16.42 21.04 17.43 21.04 18.54V19.5H2.96V18.54C2.96 17.43 3.38 16.42 4.13 15.67C4.88 14.92 5.9 14.5 7 14.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "UX/UI Design",
      description: "I create user-centered designs, from wireframes to interactive prototypes, always focused on clarity, accessibility, and seamless experiences."
    },
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M20 4L12 12L4 4" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4 4V20" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20 10V4H14" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 12L12 20" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "Full-Stack Development",
      description: "I'm actively expanding my full-stack skills, particularly with React, Node.js, and database management, with a focus on building responsive and high-performance web applications."
    },
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M12 2L2 7L12 12L22 7L12 2Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2 17L12 22L22 17" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2 12L12 17L22 12" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "Problem-Solving Mindset",
      description: "I go beyond design—blending design and development to solve problems. Whether addressing user pain points or bridging design and code, I focus on creating seamless experiences that work better."
    }
  ];

  const attributes = [
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M21 14H14V21H21V14Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10 14H3V21H10V14Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21 3H14V10H21V3Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10 3H3V10H10V3Z" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "Kanban Enthusiast",
      description: "I organize my workflow with the Kanban method, focusing on prioritizing tasks and delivering quality work efficiently. I've worked with Scrum and Jira, and I find that a structured, transparent workflow leads to better collaboration and successful outcomes."
    },
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M21 21H3V3" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M19 5L13 11L9 17L5 13" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "Data-Informed Design",
      description: "Data drives my design decisions. I believe understanding user behavior and measuring success is key to improving user experiences. I always aim to gather insights, set clear metrics, and iterate based on real-world feedback."
    },
    {
      icon: (
        <svg viewBox="0 0 24 24" fill="none" className="w-12 h-12" stroke="currentColor" strokeWidth="2">
          <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      title: "Tech-Savvy Collaborator",
      description: "I've developed a strong understanding of technical concepts, which allows me to work closely with developers. I ensure designs are feasible, and where challenges arise, I collaborate creatively to find the best solution."
    }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="mb-16 text-center">
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-8 mt-16 about-text">
          SKILLS & ATTRIBUTES
        </h2>
          
          <div className="inline-flex bg-gray-100 p-1 rounded-lg">
            <button 
              onClick={() => setActiveTab('skills')}
              className={`py-2 px-6 rounded-md text-lg font-medium transition-all duration-300 ${activeTab === 'skills' ? 'bg-black text-white shadow-md' : 'text-gray-600 hover:bg-gray-200'}`}
            >
              Core Skills
            </button>
            <button 
              onClick={() => setActiveTab('attributes')}
              className={`py-2 px-6 rounded-md text-lg font-medium transition-all duration-300 ${activeTab === 'attributes' ? 'bg-black text-white shadow-md' : 'text-gray-600 hover:bg-gray-200'}`}
            >
              Key Attributes
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {activeTab === 'skills' ? (
            skills.map((skill, index) => (
              <div key={index} className="group">
                <div className="flex flex-col items-center p-6 bg-white rounded-xl transition-all duration-300 hover:shadow-xl border border-gray-100 h-full">
                  <div className="w-24 h-24 flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 mb-6 group-hover:bg-yellow-500 group-hover:text-white transition-all duration-300">
                    {skill.icon}
                  </div>
                  <h3 className="text-2xl font-semibold mb-4">{skill.title}</h3>
                  <p className="text-gray-600 text-center">{skill.description}</p>
                </div>
              </div>
            ))
          ) : (
            attributes.map((attribute, index) => (
              <div key={index} className="group">
                <div className="flex flex-col items-center p-6 bg-white rounded-xl transition-all duration-300 hover:shadow-xl border border-gray-100 h-full">
                  <div className="w-24 h-24 flex items-center justify-center rounded-full bg-gray-100 text-gray-600 mb-6 group-hover:bg-black group-hover:text-white transition-all duration-300">
                    {attribute.icon}
                  </div>
                  <h3 className="text-2xl font-semibold mb-4">{attribute.title}</h3>
                  <p className="text-gray-600 text-center">{attribute.description}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;