import React from 'react';
import { ArrowRight } from 'lucide-react';

const LeStudioSection = () => {
  return (
    <div id="behance" className="bg-black text-white min-h-screen flex flex-col justify-center items-center px-4  mt-32">
      <div className="text-base mb-8 opacity-70">
        Discover more Works →
      </div>
      
      <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold text-center leading-tight mb-12">
        BEYOND UX
        <br />
        
      </h1>
      
      <div className="w-full max-w-xl mb-12">
        <img 
          src="/images/d-banner.png" 
          alt="LeStudio concept" 
          className="w-full rounded-2xl object-cover shadow-lg"
        />
      </div>
      
      <p className="text-center max-w-2xl mb-12 text-lg opacity-80">
        IF you want to see more of my works in other fields like Art Direction, Branding & Visual ID.
        You can visit my Behance portfolio.
      </p>
      
      <button 
      onClick={() => window.open('https://ariane-souza-more.myportfolio.com/', '_blank')}
      className="group flex items-center gap-2 border-2 border-white px-6 py-3 rounded-full hover:bg-white hover:text-black transition-all duration-300">
        <span>Visit</span>
        <ArrowRight className="group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  );
};

export default LeStudioSection;