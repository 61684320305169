import React, { useState, useEffect, useRef } from 'react';
import { FaCode, FaBriefcase, FaGraduationCap, FaServer, FaPalette, FaUserCheck, FaSearch } from 'react-icons/fa';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer, Tooltip } from "recharts";
import { motion, useMotionValue, useTransform, useSpring, AnimatePresence, useScroll } from 'framer-motion';

const skillsData = [
  {
    title: "Frontend Architecture & Performance",
    skills: ["Microfrontends", "Web Performance Optimization (Lighthouse, Web Vitals)", "Code Splitting & Bundle Analysis", "Server-Side Rendering (SSR) & Static Site Generation (SSG)", "Progressive Web Apps (PWA)", "Caching Strategies (Service Workers, CDN)", "WebSockets"],
    icon: <FaCode />,
    color: "#3B82F6", // blue
    gradient: "linear-gradient(135deg, rgba(59, 130, 246, 0.2), rgba(37, 99, 235, 0.7))"
  },
  {
    title: "Frontend Technologies",
    skills: ["React", "Next.js","GSAP", "TypeScript", "Redux", "Zustand", "Context API", "Tailwind CSS", "Framer Motion", "Storybook", ],
    icon: <FaServer />,
    color: "#10B981", // green
     gradient: "linear-gradient(135deg, rgba(16, 185, 129, 0.2), rgba(37, 99, 235, 0.7))"
  },
  {
    title: "Backend & APIs",
    skills: ["Node.js", "Express", "GraphQL", "REST API", "Firebase", "Prisma", "Authentication & Authorization (OAuth, JWT)"],
    icon: <FaBriefcase />,
    color: "#6366F1", // indigo
    gradient: "linear-gradient(135deg, rgba(99, 102, 241, 0.2), rgba(37, 99, 235, 0.7))"
  },
  {
    title: "DevOps & Tooling",
    skills: ["CI/CD (GitHub Actions, Jenkins)", "Docker, Vite, Webpack", "Figma/Framer", "Monorepos & Package Management"],
    icon: <FaPalette />,
    color: "#EC4899", // pink
    gradient: "linear-gradient(135deg, rgba(236, 72, 153, 0.2), rgba(37, 99, 235, 0.7))"
  },
  {
    title: "Design Systems & UI/UX",
    skills: ["Component-Driven Development", "Design Tokens & Theming", "Accessibility (a11y) & Inclusive Design", "Motion Design (Framer, Lottie)"],
    icon: <FaGraduationCap />,
    color: "#F59E0B", // amber
    gradient: "linear-gradient(135deg, rgba(245, 158, 11, 0.2), rgba(37, 99, 235, 0.7))"

  },
  {
    title: "Soft Skills",
    skills: [
      { skill: "Problem-Solving", level: 10 },
      { skill: "Autonomy", level: 11 },
      { skill: "Leadership", level: 10 },
      { skill: "Creativity", level: 11 },
      { skill: "Adaptability", level: 10 },
      { skill: "Collaboration", level: 10 }
    ],
    icon: <FaUserCheck />,
    color: "#8B5CF6", // violet
    gradient: "linear-gradient(135deg, rgba(139, 92, 246, 0.2), rgba(37, 99, 235, 0.7))"

  },
];



const SkillsCard = ({ title, skills, icon, color, gradient, darkMode, index, searchTerm, isVisible }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef(null);
  
  // Mouse position for magnetic effect
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  
  // Transform mouse position to card rotation
  const rotateX = useTransform(y, [-100, 100], [5, -5]);
  const rotateY = useTransform(x, [-100, 100], [-5, 5]);

  // Spring physics for smoother motion
  const springX = useSpring(rotateX, { stiffness: 200, damping: 30 });
  const springY = useSpring(rotateY, { stiffness: 200, damping: 30 });

  // Handle mouse movement for magnetic effect
  const handleMouseMove = (e) => {
    const rect = cardRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    // Calculate distance from center
    x.set((e.clientX - centerX) / 5); 
    y.set((e.clientY - centerY) / 5);
  };

  const handleMouseLeave = () => {
    // Reset position when mouse leaves
    x.set(0);
    y.set(0);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.7,
        delay: index * 0.15,
        ease: [0.16, 1, 0.3, 1] // Custom cubic-bezier curve for more modern feel
      }
    }
  };

  const renderTick = ({ x, y, payload }, darkMode) => {
    const words = payload.value.split(" ");
    return (
      <text x={x} y={y} textAnchor="middle" fill={darkMode ? "#ffffff" : "#000000"} fontSize={12} fontWeight="bold">
        {words.map((word, index) => (
          <tspan key={index} x={x} dy={index === 0 ? 0 : 14}>
            {word}
          </tspan>
        ))}
      </text>
    );
  };

  const filteredSkills = title === "Soft Skills" 
    ? skills 
    : skills.filter(skill => 
        !searchTerm || skill.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const shouldHighlight = searchTerm && filteredSkills.length < skills.length;

  // Click outside handler for collapsing expanded cards
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={`p-3 ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl rounded-lg text-sm backdrop-blur-sm ${darkMode ? 'bg-opacity-80' : 'bg-opacity-80'} border border-gray-200 dark:border-gray-700`}>
          <p className="font-medium">{payload[0].payload.skill}: {payload[0].value}/12</p>
        </div>
      );
    }
    return null;
  };

  // Determine if this is a soft skills card
  const isSoftSkills = title === "Soft Skills";

  return (
    <motion.div 
      ref={cardRef}
      variants={cardVariants}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      whileHover={{ 
        scale: 1.02,
        boxShadow: darkMode ? 
          '0 25px 50px -12px rgba(0, 0, 0, 0.5)' : 
          '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ 
        rotateX: springX,
        rotateY: springY,
        perspective: '1000px',
        transformStyle: 'preserve-3d',
      }}
      className={`p-6 rounded-2xl transition-all duration-300 ${darkMode ? 'bg-gray-800/90' : 'bg-white/90'} 
        backdrop-blur-sm
        ${darkMode ? 'shadow-lg shadow-gray-900/30' : 'shadow-xl shadow-blue-500/10'}
        ${shouldHighlight ? 'ring-2 ring-offset-2 ring-blue-500' : ''}
        ${isExpanded ? 'z-10 h-auto' : 'h-full'}
        cursor-pointer overflow-hidden relative`}
      onClick={() => !isSoftSkills && setIsExpanded(!isExpanded)}
    >
      {/* Gradient border effect */}
      <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-r" style={{ background: gradient }}></div>
      
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-3">
            <motion.div 
              className="text-xl p-3 rounded-xl" 
              style={{ 
                background: gradient,
                boxShadow: `0 10px 15px -3px ${color}30`
              }}
              whileHover={{ 
                scale: 1.1,
                rotate: [0, -5, 5, -5, 0],
                transition: { duration: 0.5 } 
              }}
            >
              <span className="text-white">{icon}</span>
            </motion.div>
            <h3 className="text-xl font-bold">{title}</h3>
          </div>
          {!isSoftSkills && (
            <motion.span 
              className="text-sm text-gray-500 h-6 w-6 flex items-center justify-center rounded-full"
              animate={{ 
                rotate: isExpanded ? 180 : 0,
                backgroundColor: isExpanded ? (darkMode ? 'rgba(59, 130, 246, 0.2)' : 'rgba(59, 130, 246, 0.1)') : 'transparent'
              }}
              transition={{ duration: 0.3 }}
            >
              {isExpanded ? '▲' : '▼'}
            </motion.span>
          )}
        </div>

        {isSoftSkills ? (
          <div className="flex-grow">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              className="w-full h-48 md:h-56"
            >
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="70%" data={skills}>
                  <PolarGrid stroke={darkMode ? "#555" : "#ddd"} strokeWidth={1} />
                  <PolarAngleAxis dataKey="skill" tick={(props) => renderTick(props, darkMode)} />
                  <Radar 
                    name="Skills" 
                    dataKey="level" 
                    stroke={color} 
                    fill={color} 
                    fillOpacity={0.6}
                    animationDuration={1500}
                    animationEasing="ease-out"
                  />
                  <Tooltip content={<CustomTooltip />} />
                </RadarChart>
              </ResponsiveContainer>
            </motion.div>
          </div>
        ) : (
          <motion.div 
            className="overflow-hidden transition-all duration-300 flex-grow"
            style={{
              height: searchTerm ? "auto" : (isExpanded ? "auto" : "auto")
            }}
          >
            <ul className="list-none list-inside space-y-2">
              {filteredSkills.slice(0, isExpanded ? filteredSkills.length : 4).map((skill, idx) => (
                <motion.li 
                  key={idx} 
                  className={`relative pl-5 py-1 rounded-lg text-sm ${searchTerm && skill.toLowerCase().includes(searchTerm.toLowerCase()) ? (darkMode ? 'bg-blue-500/20' : 'bg-blue-100') : ''}`}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ 
                    opacity: 1, 
                    x: 0,
                  }}
                  transition={{ duration: 0.3, delay: idx * 0.05 }}
                  whileHover={{ x: 3 }}
                >
                  <span className="absolute left-0 top-1/2 transform -translate-y-1/2 w-1.5 h-8 rounded-full" style={{ background: gradient }}></span>
                  <span className="block font-medium">{skill}</span>
                </motion.li>
              ))}
            </ul>

            {!isExpanded && !searchTerm && !isSoftSkills && filteredSkills.length > 4 && (
              <motion.div 
                className="mt-3 text-xs font-medium"
                whileHover={{ scale: 1.05 }}
                style={{ color }}
              >
                + {filteredSkills.length - 4} more skills
              </motion.div>
            )}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const Skills = ({ darkMode }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });

  // Transform scroll progress to parallax effect
  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] } }
  };

  
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const yOffset = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [100, 0, 0, 100]);

  return (
    <section id="skills" className="py-32 relative overflow-hidden" ref={sectionRef}>
      {/* Background gradient */}
      <div className={`absolute inset-0 ${darkMode ? 'bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900' : 'bg-gradient-to-b from-white via-blue-50 to-white'}`}></div>
      
    
      
      {/* Parallax background element */}
      <motion.div 
        className="absolute inset-0 opacity-10 pointer-events-none"
        style={{ 
          y,
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23${darkMode ? '444' : '3B82F6'}' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E")`,
        }}
      ></motion.div>

      <div className="container mx-auto px-4 sm:px-6 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
        >
          <motion.div variants={titleVariants} className="text-center mb-16">
             <motion.div 
             
            >
              
              
              <motion.h2 
                          className="text-6xl md:text-8xl font-black mb-3 tracking-tight tracking-wider text-center"
                          initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
                          whileInView={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
                          viewport={{ once: true }}
                          transition={{ duration: 1, ease: "easeOut" }}
                        >
                          <div className={`${darkMode ? 'text-white' : 'text-gray-900'} tracking-[0.2em] font-light mb-0`}>
                              + SKILLS AND TOOLS
                              </div>
              
                          <span className="text-blue-500 ml-4"></span>
                        </motion.h2>
            </motion.div>
            
            <motion.div 
              className={`mt-10 max-w-md mx-auto relative group`}
              variants={titleVariants}
            >
              <motion.div 
                className="absolute inset-0 rounded-full opacity-70 group-hover:opacity-100 transition-opacity duration-300"
                style={{ 
                  background: "linear-gradient(-45deg, #3B82F6, #3B82F6, #3B82F6, #3B82F6)",
                  backgroundSize: "400% 400%",
                  filter: "blur(8px)",
                  zIndex: -1,
                  margin: "-4px",
                }}
                animate={{
                  backgroundPosition: ["0% 0%", "100% 100%"],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "mirror",
                }}
              />
              
              <input
                type="text"
                placeholder="Search skills..."
                className={`w-full p-4 pl-12 rounded-full border ${
                  darkMode 
                    ? 'bg-gray-800 border-gray-700 text-white placeholder:text-gray-400' 
                    : 'bg-white border-gray-200 text-gray-800 placeholder:text-gray-500'
                } focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-lg`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-blue-500 text-lg" />
              {searchTerm && (
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 w-6 h-6 flex items-center justify-center rounded-full bg-gray-200 dark:bg-gray-700"
                  onClick={() => setSearchTerm("")}
                >
                  ✕
                </motion.button>
              )}
            </motion.div>
          </motion.div>

          {/* Improved grid layout with consistent card sizes and 3x2 grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
            {skillsData.map((skill, index) => (
              <SkillsCard 
                key={index}
                index={index}
                title={skill.title} 
                skills={skill.skills} 
                icon={skill.icon}
                color={skill.color}
                gradient={skill.gradient}
                darkMode={darkMode}
                searchTerm={searchTerm}
                isVisible={isVisible}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Skills;