import React, { useState } from 'react';
import { X, Instagram, Linkedin, Menu, Github } from 'lucide-react';

const ContactSection = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // Function to handle smooth scrolling
  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false); // Close menu after navigation
  };

  return (
    <div id="contact">
      <footer className="bg-white py-16 px-4 sm:px-8">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold leading-tight mb-12">
            CONTACT ME
          </h2>
          <div className="flex flex-col items-center space-y-8">
            {/* Contact Information */}
            <div>
              <a
                href="mailto:arianesouzareal@gmail.com"
                className="text-xl hover:underline"
              >
                contact@arianesouza.com
              </a>
            </div>
            {/* Socials */}
            <div className="flex justify-center space-x-4">
  
    <a href="https://www.linkedin.com/in/ariane-souza-dev/"
    className="hover:opacity-75"
    aria-label="LinkedIn"
  >
    <Linkedin size={24} />
  </a>
  
    <a href="https://github.com/Arireal"
    className="hover:opacity-75"
    aria-label="GitHub"
  >
    <Github size={24} />
  </a>
  {/* You can add more social icons here */}
</div>
            
            {/* Added Navigation Menu */}
            <div className="mt-12 pt-8 border-t border-gray-200 w-full max-w-2xl">
              <p className="text-sm text-gray-500 mb-6">Navigate to:</p>
              
              {/* Desktop navigation */}
              <div className="hidden md:flex items-center justify-center flex-wrap gap-4">
                <button 
                  onClick={() => handleScroll('about')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
                >
                  About
                </button>
                <button 
                  onClick={() => handleScroll('latest-works')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
                >
                  Works
                </button>
                <button 
                  onClick={() => handleScroll('services')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
                >
                  Services
                </button>
                <button 
                  onClick={() => handleScroll('testimonials')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
                >
                  Testimonials
                </button>
                <button 
                  onClick={() => handleScroll('behance')} 
                  className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors"
                >
                  Behance
                </button>
                <a href="https://www.arianesouza.com" target="_blank" rel="noopener noreferrer">
                  <button className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors">
                    Dev. Core
                  </button>
                </a>
              </div>
              
              {/* Mobile navigation */}
              <div className="flex items-center justify-center md:hidden">
                <a href="https://www.arianesouza.com" target="_blank" rel="noopener noreferrer" className="mr-4">
                  <button className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors">
                    Dev. Core
                  </button>
                </a>
                <button 
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="p-2 rounded-md border border-black"
                >
                  <Menu size={20} />
                </button>
              </div>
              
              {/* Mobile menu drawer */}
              {isMenuOpen && (
                <div className="md:hidden mt-4 py-4 px-6 bg-white shadow-md rounded-lg">
                  <div className="flex flex-col space-y-4">
                    <button 
                      onClick={() => handleScroll('about')} 
                      className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                    >
                      About
                    </button>
                    <button 
                      onClick={() => handleScroll('latest-works')} 
                      className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                    >
                      Works
                    </button>
                    <button 
                      onClick={() => handleScroll('services')} 
                      className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                    >
                      Services
                    </button>
                    <button 
                      onClick={() => handleScroll('testimonials')} 
                      className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                    >
                      Clients
                    </button>
                    <button 
                      onClick={() => handleScroll('behance')} 
                      className="text-black px-3 py-1 rounded-full border border-black hover:bg-gray-100 transition-colors w-full text-left"
                    >
                      Behance
                    </button>
                  </div>
                </div>
              )}
            </div>
            
          
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ContactSection;