import React, { useState, useEffect } from 'react';
import { FaSun } from 'react-icons/fa';
import { GiOwl } from "react-icons/gi";

const Header = ({ darkMode, toggleDarkMode }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  // Handle scroll event to change header appearance
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Smooth scroll function
  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80, // Offset for header height
        behavior: 'smooth'
      });
      // Close mobile menu if open
      if (isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    }
  };

  return (
    <header className={`fixed w-full transition-all duration-300 z-50 ${
      isScrolled ? 'bg-white shadow-md dark:bg-gray-800' : 'bg-transparent'
    }`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <a href="#" className={`text-xl font-bold ${
          isScrolled ? 'text-black dark:text-white' : 'text-gray-800 dark:text-white'
        }`}>
          Ariane Souza
        </a>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-8">
          {['About', 'Services', 'Projects', 'Contact'].map(item => (
            <a
              key={item}
              href={`#${item.toLowerCase()}`}
              onClick={(e) => scrollToSection(e, item.toLowerCase())}
              className={`transition-colors duration-200 relative group ${
                isScrolled 
                ? 'text-black hover:text-gray-700 dark:text-gray-300 dark:hover:text-white' 
                : 'text-gray-800 hover:text-black dark:text-white dark:hover:text-white'
              }`}
            >
              {item}
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#4e80ee] transition-all duration-300 group-hover:w-full"></span>
            </a>
          ))}
          <a
            href="https://www.arianesouza.com/ux"
            className={`mr-3 px-4 py-1.5 rounded-lg transition-all duration-200 text-sm shadow-md ${
              isScrolled
              ? 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'
              : 'border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-white dark:text-white dark:hover:bg-white dark:hover:text-black'
            }`}
          >
            UX Core
          </a>
          
          {/* Desktop Dark Mode Toggle */}
          <button
            onClick={toggleDarkMode}
            className="p-2 bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 rounded-full hover:shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Toggle dark mode"
          >
            {darkMode ? (
              <FaSun className="w-5 h-5 text-white" />
            ) : (
              <GiOwl className="w-5 h-5 text-gray-600 dark:text-gray-300" />
            )}
          </button>
        </nav>

        {/* Mobile Menu - Reworked */}
        <div className="flex items-center md:hidden">
          <a
            href="https://www.arianesouza.com/ux"
            className={`mr-3 px-4 py-1.5 rounded-lg transition-all duration-200 text-sm shadow-md ${
              isScrolled
              ? 'border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'
              : 'border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white dark:border-white dark:text-white dark:hover:bg-white dark:hover:text-black'
            }`}
          >
            UX Core
          </a>

          <button
            onClick={toggleDarkMode}
            className="mr-3 p-2 bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 rounded-full hover:shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Toggle dark mode"
          >
            {darkMode ? (
              <FaSun className="w-4 h-4 text-white" />
            ) : (
              <GiOwl className="w-4 h-4 text-gray-600 dark:text-gray-300" />
            )}
          </button>
          
          <button
            onClick={toggleMenu}
            className="flex items-center justify-center w-10 h-10 rounded-lg bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 shadow-sm"
          >
            {isMobileMenuOpen ? (
              <span className="text-xl font-light">×</span>
            ) : (
              <span className="text-xl">≡</span>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white dark:bg-gray-800 shadow-lg rounded-b-lg overflow-hidden">
          <div className="container mx-auto px-4 py-4">
            <nav className="flex flex-col space-y-4">
              {['Projects','About', 'Services', 'Contact'].map(item => (
                <a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  onClick={(e) => scrollToSection(e, item.toLowerCase())}
                  className="text-black dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200 py-2 border-b border-gray-100 dark:border-gray-700"
                >
                  {item}
                </a>
              ))}
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;