// Projects
const projects = [
    
    {
      id: 1,
      title: "E-commerce Catalog",
      date: "2025",
      modalSkills: ['React', 'Node.js', 'Express', 'JSON', 'API calls', 'Problem Solving','CORS','Render', 'React Hooks'], 
      description: `Developed a dynamic, full-stack e-commerce application with React on the frontend and Node.js/Express on the backend, 
      focusing on scalability, real-time inventory management, and smooth user experience.`,
      categories: ["fullstack", "React", 'Node.js', 'Express','API calls'],
      tags: ['React', 'Node.js', 'Express','API calls', 'React Hooks'],
      githubUrl: "https://github.com/Arireal/storefront",
      liveUrl: "https://www.adwstudium.com",
      caseStudyUrl: "/case-study/ecommerce-platform",
      image: "/api/placeholder/800/450",
      client: "ADW Studium",
      modalDescription: `<strong>My Role: Full Stack Developer & UX/UI</strong>
      <br />
      <br />
     Developed a dynamic, front-end storefront application designed to display a curated selection of products. 
     Users can browse through product categories and click through to external links for more details or to make purchases.
    The application was built using React for the frontend and Node.js/Express for the backend to manage product data and API calls.
      <br />
      <br />
      Key features of the project include a modular, component-driven architecture that supports scalable and maintainable code. I used React hooks such as useState and useEffect
      to manage state and dynamic updates in the UI, ensuring real-time changes in product data are reflected instantly. I also focused on performance optimization to deliver fast page 
      transitions and smooth browsing, even with a large set of products.
      <br />
      <br />
      The application was deployed on Render, with a focus on ensuring reliable uptime, performance, and accessibility across all devices. The modular architecture allows for easy scaling of 
      both the frontend and backend as needed.
      <br />
      <br />

     Check out the live website:
      <a href="https://www.adwstudium.com/" target="_blank" class="text-blue-600 hover:underline">See the live website</a> 
      | 
      <a href="https://github.com/Arireal/storefront" target="_blank" class="text-blue-600 hover:underline">See the code</a>`,
      visuals: ["/images/storefront/s2.png", "/images/storefront/s00.png", "/images/storefront/s3.png"],

      features: ['Dynamic Product Display: Products are fetched via API calls, ensuring real-time updates and providing users with the most current product information.',
         'Polyrepo Setup: Separated the frontend and backend into independent repositories, allowing independent scaling and deployments.',
         'Real-time Inventory Management: Integrated with a backend to fetch product data in real-time, offering dynamic updates as inventory changes.', 'Real-time inventory management', 'Reusable components like Header, Footer, and MasonryGrid,', 
          'State Management: Leveraged React hooks like useState and useEffect for dynamic state updates, ensuring a seamless and responsive user interface.',
        'API Integration: Managed CORS settings and designed optimized API calls to ensure fast data retrieval and smooth frontend-backend interaction.',
      'Modular, Component-Driven Architecture: Utilized reusable React components to build a scalable and maintainable product catalog.'],

      ux:['Custom Grid Layouts: Designed flexible, custom grid layouts for displaying products efficiently.', 'Smooth Transitions & Animations: Used animations for page transitions to create a fluid and engaging browsing experience.',
       'User-friendly Navigation: Implemented an intuitive navigation system, primarily controlled via footer buttons, making it easy for users to explore product categories and manage their cart.'],
      process: ['User Research: Gathered insights on user behavior to inform design decisions.', 'Wireframing & UI Design: Designed user-friendly interfaces with intuitive flows and visual appeal',
         'Frontend & Backend Development: Utilized React for dynamic UI components and Node.js/Express for building the backend APIs and handling data fetching.'],
      Role:'Full Stack Developer UX/UI',
     image: "/images/storefront/new-storefront-thumb.png"
    },
    {
      id: 2,
      title: "Modular Portfolio",
      date:"2025",
      description: 'Built a react website with multiple pages using react router, GSAP and Tailwind.',
      modalSkills: ['React','Next.js','React Router', "GSAP", 'Grid UI', 'UX/UI', 'Tailwind CSS', 'React Hooks', 'Framer Motion'], 
      categories: ["frontend","React", 'GSAP','Next.js','Tailwind CSS', 'Framer Motion'],
      tags: ['React', 'Next.js','Tailwind CSS','GSAP' ,'React Router', 'Framer Motion'],
      modalDescription: `<strong>My Role: Frontend Developer UX/UI</strong>
    <br />
    <br />
    Developed a fully modular, interactive portfolio website designed to showcase both my frontend and UX/UI design projects. 
    Built using React, the portfolio features a dynamic, grid-based layout with smooth animations powered by GSAP and Framer Motion. 
    The site is structured to be easily updatable, allowing me to add new projects and adjust sections as needed with minimal effort.
    <br />
    <br />
    The project leverages React Router for seamless navigation across multiple pages, and Tailwind CSS was used to ensure a responsive 
    and aesthetically minimal design. Key features include dark mode/light mode toggle, which enhances the user experience, and the integration 
    of Lucide and React Icons for modern UI elements. The dashboard-style layout organizes projects, skills, and personal insights, making it intuitive for visitors to explore.
    <br />
    It features a dark mode/light mode toggle, allowing users to customize the viewing experience based on their preference.
     <br />
    <a href="https://www.arianesouza.com/" target="_blank" class="text-blue-600 hover:underline">See the live website</a> 
    | 
    <a href="https://github.com/Arireal/React-Portfolio" target="_blank" class="text-blue-600 hover:underline">See the code</a>` ,
    visuals: [ "/images/react-portfolio/folio-gif.gif"],
    features: ['Modular Design: Ensured high modularity with reusable components, making it easy to update and extend the portfolio as new projects and sections are added.',

      'Dark Mode/Light Mode: Allows users to toggle between dark and light themes for a personalized viewing experience.', 
      'React Hooks (useState) - Utilized React hooks like useState to manage the state of dynamic components, improving the interactivity and responsiveness of the app.',
      'Tailwind Css - Utilized for a modern, sleek, and responsive design that adapts beautifully to all screen sizes.',
      'Smooth Navigation –mplemented smooth scroll navigation for fluid transitions between sections, optimizing user interaction.',
      'Smooth Scroll Navigation –Ensures seamless transitions between sections, enhancing overall user experience.',
      'Lucide & React Icons – Clean and modern UI elements that add to the visual appeal.',
      'Integrated Recharts –Displays skills and technical knowledge in an engaging, visual format.',
      'GSAP & Framer Motion Animations:Integrated smooth animations to improve the user experience and add visual appeal.'
    ],
    ux: ['Minimalist Design – A clean, professional aesthetic for readability and focus.', 
      'Structured Information Flow – Ensures clarity in presenting skills and work approach.',
      'Interactive Project Showcase –Ensures recruiters can easily explore my projects, skills, and work approach with simple, interactive elements.',
      'Interactive Project Showcase:A clean, structured, and interactive display of my work, making it easy for potential clients or employers to explore my skills and projects.'
    ],
    Role:'Frontend Developer UX/UI',
      githubUrl: "https://github.com/Arireal/React-Portfolio", 
      liveUrl: "https://www.arianesouza.com",
      caseStudyUrl: "/case-study/design-system",
      image: "/images/react-portfolio/new-thumbr2.png",
    },
    {
      id: 3,
      date: "2024",
      title: "Music Studio Landing Page",
      description: "Developed a dynamic landing page for Vibrant Audio, incorporating real-time music data from the Last.fm API to showcase trending artists, albums, and top charts, enhancing user engagement and interactivity.",
      modalSkills: ["React", "API Fetch","JavaScript", "CSS", "Responsive Design", "UI/UX", "GSAP", "Last.fm API"],
      modalDescription: ` 
        <strong>My Role: Frontend Developer</strong>
        <br /><br />
        I built a modern, dynamic landing page for Vibrant Audio using React to create an interactive user interface. The project involved fetching real-time data from the Last.fm API, allowing the site
        to display trending artists, albums, and top charts, making the site dynamic and engaging. This API integration is designed to keep the content fresh and relevant for visitors.
        <br /><br />
        To enhance user experience, I incorporated GSAP animations with scroll-triggered effects, ensuring smooth transitions between sections as users scroll. 
        This improves navigation and makes the browsing experience feel more fluid and intuitive.
        <br /><br />

        <a href="https://www.ariane-dev.music-studio.adwstudium.com/" target="_blank" class="text-blue-600 hover:underline">See the live website</a>`,
      
      visuals: ["/images/vibrant/vs2.png"],
      
      features: [
        "React Development: Built the landing page with **React**, ensuring a dynamic and interactive UI.",
        "API Fetching: Integrated Last.fm API to display trending artists, albums, and top charts in real time, keeping the content up-to-date.",
        "GSAP Animations: Added croll-triggered animations using **GSAP** to make page navigation smoother and more engaging.",
        "CSS Styling: Applied modern CSStechniques for a responsive and visually appealing layout.",
        "Music Studio Branding: Focused on promoting the studio’s services and brand identity with clear, engaging visuals.",
        "Responsive Design: Ensured the site is fully responsive, optimizing the experience across mobile and desktop devices."
      ],
      
      ux: [
        "Responsive Layout: The landing page seamlessly adapts to all screen sizes, ensuring a smooth experience across mobile and desktop.",
        "Interactive Features: The real-time music data** adds an engaging dynamic to the page, encouraging visitors to explore and interact with the content.",
        "Smooth Navigation: GSAP animations improve page transitions, making navigation feel fluid and natural.",
        "Future Enhancements: Designed with scalability in mind to easily integrate more interactive music features as the studio expands its content."
      ],
      
    categories: ["frontend","React", "API Fetch",  "GSAP","CSS", "UI/UX",  "Last.fm API"],
      
    githubUrl: "https://github.com/Arireal/vibrant-audio-react-landing-page/",
      
      liveUrl: "https://www.ariane-dev.music-studio.adwstudium.com/",
      
      caseStudyUrl: "/case-study/design-system",
      
      image: "/images/vibrant/vs2.png"
    },
         
      {
        id: 4,
        date:"2024",
        title: "Book Landing Page",
        description: 'Developed and designed a mobile-optimized landing page to promote a book, focusing on UX/UI, SEO, and interactive elements.',
        tags: ['HTML5', 'CSS', 'JavaScript', 'UX/UI', 'SEO', 'PHP', 'Bootstrap'],
        visuals: ["/images/hbook/gif-video.gif", "/images/hbook/hf2.png", "/images/hbook/hf3.png", "/images/hbook/hf4.gif", "/images/hbook/hf5.gif"],
        categories: ["Others"],
        modalSkills: ['HTML5', 'CSS', 'JavaScript', 'UX/UI', 'SEO', 'PHP', 'Bootstrap'], 
         modalDescription: `<strong>My Role: Frontend Developer & Web Designer</strong>
         <br />
  <br />
  I designed and developed a one-page landing website to promote the book, ensuring a mobile-first approach, as most users accessed it via mobile devices. 
  <br />
  <br />
  The project started with persona research, which informed the UX/UI decisions and the wireframing process. Since this was a redesign, I iteratively improved the 
  existing prototype, ensuring it aligned with the book’s visual identity.
  <br />
  <br />
  I built the entire layout using <strong>HTML5, CSS, JavaScript libraries, and flex grids</strong>, ensuring an adaptive and seamless experience across all devices. 
  To maximize user retention, all links, including newsletter and contact forms, open in pop-up tabs rather than redirecting users away from the page.
  <br />
  <br />
  Additionally, I implemented essential frontend <strong>SEO settings</strong> to improve search engine indexing and configured <strong>PHP-based email forms</strong> 
  for efficient contact and newsletter sign-ups.`,
  Role: 'Frontend Developer & Web Designer',
  features: [
    'Mobile-Optimized Design: Ensured seamless performance and user experience on all devices, prioritizing mobile users.',
    'Interactive Elements: Implemented pop-up forms and JavaScript-driven interactions to keep users engaged on the page.',
    'SEO Optimization: Configured metadata and on-page SEO elements to enhance search engine visibility.',
    'Contact & Email Forms: Developed PHP-based forms to handle inquiries and newsletter sign-ups efficiently.',
    'Visual Identity: Maintained consistency with the book’s branding through typography, color schemes, and layouts.'
  ],
  ux: [
    'User-Centered Wireframing: Conducted persona research to create an intuitive and visually engaging experience.',
    'Navigation Optimization: Simplified navigation and interaction to enhance user retention.',
    'Performance Focus: Built with lightweight code and optimized assets for faster load times.'
  ],
       
        liveUrl: "https://www.hofindatheresumptionofties.aogaroi.com/",
        caseStudyUrl: "/case-study/design-system",
        image: "/images/genotec/hof-thumb.jpg",
      },
      {
        id: 5,
        title: "Extranet Relayout",
        date:"2025",
        client: "Vila Vela Insurance Broker",
        modalSkills: ['Framer', 'ASP.NET MVC','C#', 'User Research', 'Persona Creation','Design Systems', 'Problem Solving', 'Wireframing','Prototyping'], 
        description: 'Redesign of the extranet system to create a modern, efficient, and user-centric experience, focusing on performance, scalability, and intuitive navigation.',
        modalDescription: `<strong>My Role: UX/UI Analyst & Partial Developer</strong>
        <br />
        <br />
        In this project, I worked on the redesign of an outdated extranet system with a focus on enhancing both usability and performance for senior stakeholders and company owners. 
        I conducted thorough user research, including persona creation and user interviews, to ensure the solution addressed key pain points and was tailored to the needs of the target audience.
        <br />
        <br />
        The design architecture was built around a modular and responsive layout, ensuring that the interface adapts seamlessly across all devices (desktops, tablets, and mobiles). To optimize user experience and performance,
        I employed progressive disclosure techniques, reducing cognitive load and ensuring users could easily prioritize important tasks. This also involved implementing micro-interactions and smooth animations in Framer to provide a 
        polished, dynamic feel without compromising speed.
       <br />
        <br />
        In addition to UX/UI design, I contributed to the partial development of the system using C# and Razor, working on front-end components and integrating them within the existing infrastructure. This allowed me to bridge
        the gap between design and development, ensuring a seamless implementation of the redesigned interface.
        <br /><br />
  The design system was fundamental in ensuring consistency across the user interface. I developed reusable components and patterns, focusing on accessibility and scalability so the system could evolve with future updates while maintaining usability and performance.
  <br /><br /> <a href='https://gentle-imagine-000316.framer.app/' target='_blank' class='text-blue-600 hover:underline'>See the live Prototype</a>`,
      
        features: ['Modular and Responsive Layout: Ensuring seamless adaptation across various devices and screen sizes, making the interface future-proof and scalable.',
           'Simplified Navigation: Integrated card sorting and progressive disclosure techniques to optimize information prioritization, improving the user\'s decision-making process.', 
           'Customizable Dashboard: A flexible, state-driven design that enables users to personalize the interface according to their needs, enhancing user satisfaction and engagement.', 
           'Modern, Minimalist Aesthetic: Focusing on sleek, functional design trends while maintaining a user-friendly and high-performance interface.',
           'Commercial Optimization: Enhanced the interface with strategically placed CTAs and sales buttons to increase conversions.' 
         ],
        visuals: ["/images/vl/vl1.png", "/images/vl/vl2.png", "/images/vl/vl3.png", 
          "/images/vl/4.png", "/images/vl/5.png", "/images/vl/6.gif", "/images/vl/15.gif",
          "/images/vl/8.png","/images/vl/9.png", "/images/vl/10.png", "/images/vl/11.png",
          "/images/vl/12.png", "/images/vl/13.png", "/images/vl/14.png", "/images/vl/18.gif",
          "/images/vl/16.png", "/images/vl/17.png", "/images/vl/10.gif","/images/vl/19.png",
          "/images/vl/20.png"
        ],
        Role: 'UX/UI Analyst & Designer',
        tags: ['Framer','ASP.NET MVC','C#','Design Systems'],
        categories: ["Others"], 
        githubUrl: "", 
        liveUrl: "https://gentle-imagine-000316.framer.app/",
        caseStudyUrl: "/case-study/extranet-relayout",
        image: "/images/vl/n-thumb.jpg"
    },
     
      {
        id: 6,
        date:"2024",
        title: "BookVerse Landing Page",
        description: 'Developed and designed a mobile-optimized landing page to promote a book, focusing on UX/UI, SEO, and interactive elements.',
        tags: ['HTML5', 'CSS', 'JavaScript', 'UX/UI', 'SEO', 'PHP', 'Bootstrap'],
        visuals: ["/images/hbook/gif-video.gif", "/images/hbook/hf2.png", "/images/hbook/hf3.png", "/images/hbook/hf4.gif", "/images/hbook/hf5.gif"],
        categories: ["Others"],
        modalSkills: ['HTML5', 'CSS', 'JavaScript', 'UX/UI', 'SEO', 'PHP', 'Bootstrap'], 
         modalDescription: `<strong>My Role: Frontend Developer & Web Designer</strong>
         <br />
  <br />
  I designed and developed a one-page landing website to promote the book, ensuring a mobile-first approach, as most users accessed it via mobile devices. 
  <br />
  <br />
  The project started with persona research, which informed the UX/UI decisions and the wireframing process. Since this was a redesign, I iteratively improved the 
  existing prototype, ensuring it aligned with the book’s visual identity.
  <br />
  <br />
  I built the entire layout using <strong>HTML5, CSS, JavaScript libraries, and flex grids</strong>, ensuring an adaptive and seamless experience across all devices. 
  To maximize user retention, all links, including newsletter and contact forms, open in pop-up tabs rather than redirecting users away from the page.
  <br />
  <br />
  Additionally, I implemented essential frontend <strong>SEO settings</strong> to improve search engine indexing and configured <strong>PHP-based email forms</strong> 
  for efficient contact and newsletter sign-ups.`,
  Role: 'Frontend Developer & Web Designer',
  features: [
    'Mobile-Optimized Design: Ensured seamless performance and user experience on all devices, prioritizing mobile users.',
    'Interactive Elements: Implemented pop-up forms and JavaScript-driven interactions to keep users engaged on the page.',
    'SEO Optimization: Configured metadata and on-page SEO elements to enhance search engine visibility.',
    'Contact & Email Forms: Developed PHP-based forms to handle inquiries and newsletter sign-ups efficiently.',
    'Visual Identity: Maintained consistency with the book’s branding through typography, color schemes, and layouts.'
  ],
  ux: [
    'User-Centered Wireframing: Conducted persona research to create an intuitive and visually engaging experience.',
    'Navigation Optimization: Simplified navigation and interaction to enhance user retention.',
    'Performance Focus: Built with lightweight code and optimized assets for faster load times.'
  ],
        githubUrl: "", 
        liveUrl: "https://www.oakextendedhalloweendays.aogaroi.com/",
        image: "/images/genotec/hof-thumb.jpg",
      },
      {
        id: 7,
        date:"2023",
        title: "Grid Portfolio",
        description: 'Built a visually appealing landing page for Vibrant Audio, a music studio, to establish its online presence, with plans for future content enhancements.',
        modalSkills: ["React", "JavaScript", "CSS", "HTML", "Responsive Design", "UI/UX"],
        modalDescription: `<strong>My Role: Frontend Developer</strong> <br /><br /> I developed a vibrant and modern landing page for Vibrant Audio, using React to build a 
        seamless user interface and ensuring a responsive design with CSS. The site includes initial information about the studio's services and brand identity, with plans to 
        integrate interactive music tracks in the future. My role focused on creating a visually engaging and user-friendly experience, optimizing it for mobile and desktop viewing.
         <br /><br /> <a href='https://www.ariane-dev.music-studio.adwstudium.com/' target='_blank' class='text-blue-600 hover:underline'>See the live website</a>`,
      
        visuals: ["/images/vibrant/vs2.png"],
        "features": [
    "React Development: Built the landing page using React to create a dynamic user interface.",
    "CSS Styling: Applied modern CSS techniques for a responsive and visually appealing layout.",
    "Music Studio Branding: Highlighted the studio’s services and brand identity to engage visitors.",
    "Responsive Design: Ensured the page was fully responsive and user-friendly across all devices."
  ],
  "ux": [
    "Responsive Layout: The landing page adapts seamlessly to mobile and desktop, offering a smooth browsing experience.",
    "User Engagement: Focused on presenting the studio’s brand identity clearly to attract potential clients.",
    "Future Enhancements: Designed with scalability in mind, with plans to add interactive music features as content expands."
  ],
       tags: ["HTML", "CSS", "UI/UX", "PHP", "Boostrap"],
        categories: ["React"],
        githubUrl: "", 
        liveUrl: "https://www.arianegaroi.com",
        image: "/images/vibrant/vs2.png",
      },
      
  ];
  export default projects;

  