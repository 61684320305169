import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Contact from './components/contact-design';
import AnimationCards from './components/animation-cards';
import HeroAbout from './components/heroabout';
import NotFeatured from './components/non-featured-design';
import Banner from './components/banner';
import Services from './components/services-ux';
import Feedback from './components/testimonials';
import Skills from './components/design-skills';



const UXCategory = ({ toggleDarkMode, forceLight }) => {
  const location = useLocation();
  console.log("UXCategory location:", location.pathname);
  
  useEffect(() => {
    // Handle forceLight effect
    if (forceLight) {
      document.body.classList.remove('dark-mode');
    }
    
    // Add overflow-x hidden to prevent horizontal scrolling
    document.body.style.overflowX = 'hidden';
    
    // Cleanup function to remove the style when component unmounts
    return () => {
      document.body.style.overflowX = '';
    };
  }, [forceLight]);
  
  useEffect(() => {
    // Check if we should scroll to works section
    if (location.state?.scrollToWorks) {
      // Small timeout to ensure the page is fully rendered
      setTimeout(() => {
        const worksSection = document.getElementById('latest-works');
        if (worksSection) {
          worksSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [location]);
  
  // Always use light mode for this component
  const darkMode = false;
  
  return (
    <div className={`min-h-screen transition-colors duration-300 ${darkMode ? 'bg-white text-gray-100' : 'bg-white text-gray-800'} overflow-x-hidden`}>
      {/* Pass darkMode as false to children */}
      <HeroAbout  darkMode={darkMode} /> 
      <Skills darkMode={darkMode}  />   
      <AnimationCards darkMode={darkMode} id="latest-works" /> 
      <NotFeatured darkMode={darkMode} />  
      <Services darkMode={darkMode}  />   
      <Feedback darkMode={darkMode}  />   
      <Banner darkMode={darkMode}  />   
      <Contact darkMode={darkMode} id="contact"/>
      
    </div>
  );
};

export default UXCategory;