import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate, useLocation } from 'react-router-dom';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Sample project data (expanded to show potential for more cards)
const projects = [
  {
     id: 5,
     name: 'Digital Campaign',
     category: 'UX Motion for Reckitt Brasil',
     image: '/images/reckitt/reckitt-mockup.png',
   
   },
   {
     id: 7,
     name: 'Information Architecture',
     category: 'UX Editorial for Google For Education',
     image: '/images/goog/gf-mockup.png',

   },
  
   {
     id: 6,
     name: '10K Mentorship',
     category: 'UI Gamefication for Mentoring Program',
     image: '/images/mentorship/m1.png',
  
   },
  
];

const PortfolioSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const projectRefs = useRef([]);

  // Function to handle navigation to project page
  const handleViewProject = (projectId, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    navigate(`/project/${projectId}`, { 
      state: { from: location.pathname } 
    });
  };

  useEffect(() => {
    // Create a GSAP context to easily kill all animations later
    const ctx = gsap.context(() => {
      projectRefs.current.forEach((projectEl, index) => {
        // Create a timeline for each project card
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 80%", // Adjusted to trigger earlier
            end: "top 60%",   // Adjusted to end later
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        // Animate the card from laying down to standing up
        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.85,
          y: 50 // Add slight vertical offset
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        });
      });
    });

    // Cleanup function
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-white py-16">
      <div className="container mx-auto px-4">
        
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" 
          style={{perspective: "1500px"}}
        >
          {projects.map((project, index) => (
            <div
              key={index}
              ref={el => projectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-4 border-black"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity",
                maxHeight: "500px" // Limit card height
              }}
            >
              <div className="relative w-full h-full min-h-[400px] max-h-[500px]">
                <img
                  src={project.image}
                  alt={project.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60"></div>
                <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                  <div className="absolute top-2 left-8">
                    <h3 className="text-3xl font-bold">{project.name}</h3>
                  </div>
                  <div className="absolute bottom-16 left-8">
                    <p className="text-sm">{project.category}</p>
                  </div>
                  <div className="absolute bottom-[350px] right-8">
                    <button
                      onClick={(e) => handleViewProject(project.id, e)}
                      className="group relative overflow-hidden rounded-full px-4 py-2 text-black bg-white text-sm transition-all duration-300 hover:bg-white hover:text-blue-500"
                    >
                      <span className="relative z-10">The Project→ </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;