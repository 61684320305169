import React, { useState, useEffect, useRef } from 'react';
import { ChevronRight, ExternalLink, Github, Eye, ArrowRight } from 'lucide-react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import ProjectModal from './ProjectModal';
import projects from "./projects-array";



const PortfolioSection = ({ darkMode}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeCategory, setActiveCategory] = useState('all');
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredProject, setHoveredProject] = useState(null);
  const sectionRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setIsVisible(true);
    
    // Set up intersection observer for scroll animations
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      setIsVisible(false);
    };
  }, []);

  < projects />
  

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  // Filter projects based on active category - now applied to ALL projects
  const filteredProjects = activeCategory === 'all'
    ? projects
    : projects.filter(project => project.categories && project.categories.includes(activeCategory));

  // Category data with counts
  const categories = [
    { id: 'all', name: 'All Projects', count: projects.length },
    { id: 'React', name: 'Frontend React', count: projects.filter(p => p.categories && p.categories.includes('React')).length },
    { id: 'Others', name: 'Frontend Others', count: projects.filter(p => p.categories && p.categories.includes('Others')).length },
    { id: 'fullstack', name: 'Full Stack', count: projects.filter(p => p.categories && p.categories.includes('fullstack')).length }
  ];

  const getCategoryColor = (category) => {
    switch(category) {
      case 'frontend': return darkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-600';
      case 'uxui': return darkMode ? 'bg-orange-900/30 text-orange-400' : 'bg-orange-100 text-orange-600';
      case 'fullstack': return darkMode ? 'bg-purple-900/30 text-purple-400' : 'bg-purple-100 text-purple-600';
      default: return darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-600';
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  // Animation variants for Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12
      }
    }
  };

  const titleVariants = {
    hidden: { y: -20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  // Featured projects - first 3 projects from filtered projects
  const featuredProjects = filteredProjects.slice(0, 3);

  // More projects - the rest of the filtered projects
  const moreProjects = filteredProjects.slice(3);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });
  
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const yOffset = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [100, 0, 0, 100]);

  const handleMouseMove = (event) => {
    setMousePosition({ 
      x: event.clientX, 
      y: event.clientY 
    });
  };

  return (
    <section 
      id="projects" 
      ref={sectionRef}
      className={`py-20 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'}`}
    >
      <div className="max-w-7xl mx-auto px-4">
        {/* Title and Subtitle - Now centered */}
        <motion.div 
          className="mb-12 text-center relative"
          style={{ opacity, y: yOffset }}
        >
          <motion.h2 
            className="text-6xl md:text-8xl font-black mb-3 tracking-tight tracking-wider text-center"
            initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
            whileInView={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <div className={`${darkMode ? 'text-white' : 'text-gray-900'} tracking-[0.2em] font-light mb-0`}>
                WORKS
                </div>

            <span className="text-blue-500 ml-4"></span>
          </motion.h2>
         
          <motion.p 
            className={`text-xl md:text-2xl max-w-xl mx-auto ${darkMode ? 'text-gray-400' : 'text-gray-600'} font-light`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.7 }}
          >
            
          </motion.p>
        </motion.div>

        {/* Featured Projects Row (larger, more prominent) */}
        <motion.div 
          className="mb-20"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {/* Only show this section if there are featured projects */}
          {featuredProjects.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {featuredProjects.map((project, index) => (
                <motion.div 
                  key={`featured-${project.id}`}
                  className={`relative overflow-hidden rounded-xl ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl transition-all duration-500`}
                  whileHover={{ 
                    y: -10,
                    transition: { duration: 0.3 }
                  }}
                  onMouseEnter={() => setHoveredProject(project.id)}
                  onMouseLeave={() => setHoveredProject(null)}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 + index * 0.1 }}
                >
                  <div className="relative aspect-[4/3] overflow-hidden">
                    <img 
                      src={project.image} 
                      alt={project.title} 
                      className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                    />
                    <div className={`absolute inset-0 bg-gradient-to-t from-black/90 to-black/50 flex items-end transition-opacity duration-300 ${hoveredProject === project.id ? 'opacity-100' : 'opacity-0'}`}>
                      <div className="p-6 text-white w-full">
                        <h3 className="font-bold text-2xl mb-2">{project.title}</h3>
                        <p className="text-gray-200 text-sm mb-4">{project.description}</p>
                        
                      </div>
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="flex flex-wrap gap-2 mb-8">
                      {project.categories && project.categories.map(cat => (
                        <span key={cat} className={`${getCategoryColor(cat)} px-3 py-1 rounded-full text-xs font-medium`}>
                          {cat}
                        </span>
                      ))}
                    </div>
                    <h3 className={`text-xl font-regular mb-2 ${darkMode ? 'text-white' : 'text-gray-800'} mb-8`}>{project.title}</h3>
                    
                    
                    <div className="flex justify-between items-center mb-4">
                      <button
                        onClick={() => handleProjectClick(project)}
                        className={`px-4 py-2 rounded-full ${darkMode ? 'border-2 border-blue-600 text-white bg-transparent hover:bg-blue-700' : 'border-2 border-blue-600 text-blue-600 bg-transparent hover:bg-gray-100'} transition-colors flex items-center gap-2`}

                      >
                        <Eye size={16} />
                        <span>Details</span>
                      </button>
                      
                      <div className="flex space-x-2">
                        {project.githubUrl && (
                          <motion.a 
                            href={project.githubUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'} transition-colors`}
                            onClick={(e) => e.stopPropagation()}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Github size={18} />
                          </motion.a>
                        )}
                        {project.liveUrl && (
                          <motion.a 
                            href={project.liveUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'} transition-colors`}
                            onClick={(e) => e.stopPropagation()}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <ExternalLink size={18} />
                          </motion.a>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          )}
        </motion.div>
        
        {/* Table View for More Projects */}
        <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
      className="mb-10"
    >
      {moreProjects.length > 0 && (
        <>
          <div className="flex items-center justify-between mb-8">
            <h3 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
              More Projects
            </h3>
          </div>
          
          <div className="w-full overflow-x-auto relative">
  <table className="w-full border-collapse table-auto"> {/* Changed from table-fixed to table-auto */}
    <thead>
      <tr className={`${darkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'}`}>
        <th className="p-4 text-left">Project</th>
        <th className="p-4 text-left hidden md:table-cell">Technologies</th>
        <th className="p-4 text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      {moreProjects.map((project, index) => (
        <tr 
          key={project.id}
          className={`
            ${darkMode 
              ? 'hover:bg-gray-700 border-b border-gray-700' 
              : 'hover:bg-gray-100 border-b border-gray-200'}
            transition-all 
            duration-300 
            group 
            relative
            ${index === 0 ? 'border-t' : ''}
          `}
          onMouseEnter={() => setHoveredProject(project)}
          onMouseLeave={() => setHoveredProject(null)}
          onMouseMove={handleMouseMove}
        >
                    {/* Project Column */}
                    <td className="p-6 align-middle">
                      <div className="flex items-center">
                        <div className="flex flex-col">
                          <span 
                            className={`
                                text-4xl 
                                font-regular
                                ${darkMode ? 'text-white' : 'text-gray-800'} 
                                group-hover:text-blue-500 
                                transition-colors
                              `}
                              
                          >
                            {project.title}
                          </span>
                          <span 
                            className={`
                              text-sm 
                              ${darkMode ? 'text-gray-400' : 'text-gray-600'}
                            `}
                          >
                            {project.date || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </td>
                  
                    {/* Technologies Column */}
                    <td className="p-6 hidden md:table-cell align-middle">
                      <div className="flex flex-wrap gap-1">
                        {project.tags && project.tags.slice(0, 3).map((tech) => (
                          <span 
                            key={tech} 
                            className={`
                              px-2 
                              py-0.5 
                              rounded-full 
                              text-xs 
                              font-medium
                              ${darkMode 
                                ? 'bg-gray-700 text-gray-300' 
                                : 'bg-gray-200 text-gray-700'}
                            `}
                          >
                            {tech}
                          </span>
                        ))}
                        {project.technologies && project.technologies.length > 3 && (
                          <span 
                            className={`
                              px-2 
                              py-0.5 
                              rounded-full 
                              text-xs 
                              font-medium
                              ${darkMode 
                                ? 'bg-gray-700 text-gray-300' 
                                : 'bg-gray-200 text-gray-700'}
                            `}
                          >
                            +{project.technologies.length - 3}
                          </span>
                        )}
                      </div>
                    </td>
                  
                    {/* Action Buttons Column */}
                    <td className="p-6 align-middle">
                      <div className="flex justify-center items-center space-x-2">
                      {project.caseStudyUrl && (
  <motion.button
    onClick={(e) => {
      e.stopPropagation();
      handleProjectClick(project);
    }}
    className={`
     p-2
     rounded-full
     transition-all
    ${darkMode
     ? 'hover:bg-blue-800 text-white'
     : 'hover:bg-blue-100 text-blue-600'}
     `}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <Eye size={18} />
  </motion.button>
)}
                
                        {project.githubUrl && (
                          <motion.a
                            href={project.githubUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            className={`
                              p-2 
                              rounded-full 
                              transition-all
                              ${darkMode 
                                ? 'hover:bg-gray-700 text-gray-300' 
                                : 'hover:bg-gray-200 text-gray-600'}
                            `}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Github size={18} />
                          </motion.a>
                        )}
                
                        {project.liveUrl && (
                          <motion.a
                            href={project.liveUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            className={`
                              p-2 
                              rounded-full 
                              transition-all
                              ${darkMode 
                                ? 'hover:bg-gray-700 text-gray-300' 
                                : 'hover:bg-gray-200 text-gray-600'}
                            `}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <ExternalLink size={18} />
                          </motion.a>
                        )}
                      </div>
                    </td>
                    <td 
            colSpan={3} 
            className="absolute inset-0 pointer-events-none z-10"
          />
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Floating Image */}
            {hoveredProject && hoveredProject.image && (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ 
                  opacity: 1, 
                  scale: 1,
                  x: mousePosition.x + 20,
                  y: mousePosition.y - 100
                }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                className="fixed z-50 pointer-events-none"
              >
                <img 
                  src={hoveredProject.image} 
                  alt={hoveredProject.title} 
                  className="max-w-[300px] max-h-[200px] object-cover rounded-lg shadow-xl"
                />
              </motion.div>
            )}
          </div>
        </>
      )}
    </motion.div>
      </div>
      {/* Using the ProjectModal component */}
      {isModalOpen && selectedProject && (
        <ProjectModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          projectData={selectedProject}
          darkMode={darkMode}
        />
      )}
    </section>
);
  
};

export default PortfolioSection;