import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaGithub, FaLinkedin } from 'react-icons/fa';

const Contact = ({ darkMode }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  
  // Using a title with the same animation style as the hero section
  const titleText = "CONTACT ME";
  const titleArray = titleText.split('');

  useEffect(() => {
    let currentIndex = 0;
    const runAnimation = () => {
      const interval = setInterval(() => {
        if (currentIndex < titleArray.length) {
          setActiveIndex(currentIndex);
          currentIndex++;
        } else {
          clearInterval(interval);
          // Reset animation after a delay
          setTimeout(() => {
            setActiveIndex(-1);
            currentIndex = 0;
            // Start the next animation cycle
            setTimeout(runAnimation, 800);
          }, 1200);
        }
      }, 100);
      return interval;
    };
    
    const interval = runAnimation();
    return () => clearInterval(interval);
  }, [titleArray.length]);

  const socialLinks = [
    "https://github.com/Arireal",
    "https://www.linkedin.com/in/ariane-souza-dev/",
  ];

  return (
    <section 
      id="contact" 
      className={`py-24 flex flex-col items-center justify-center transition-colors duration-500 ${
        darkMode ? 'bg-black text-white' : 'bg-white text-blue-900'
      }`}
    >
      <div className="container mx-auto px-4 text-center">
        {/* Animated title using the same animation logic as hero */}
        <h1 className="text-4xl md:text-6xl tracking-tighter mb-12 flex flex-wrap justify-center">
          {titleArray.map((letter, index) => (
            <span
              key={index}
              className={`transition-all duration-200 inline-block ${
                index === activeIndex
                  ? 'transform scale-150 translate-y-3 font-bold'
                  : index < activeIndex && index % 2 === 0
                  ? 'transform -translate-y-3 font-normal'
                  : index < activeIndex && index % 2 === 1
                  ? 'transform translate-y-3 font-normal'
                  : 'font-extralight'
              }`}
              style={{
                transformOrigin: 'center bottom',
                transition: index === activeIndex ? 'all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'all 0.2s ease'
              }}
            >
              {letter === ' ' ? '\u00A0' : letter}
            </span>
          ))}
        </h1>

        <p className="text-xl max-w-2xl mx-auto mb-12">
          Interested in working together? Feel free to reach out. I'm always open to discussing new projects and opportunities.
        </p>

        {/* Email contact with improved styling */}
        <div className={`inline-block mb-12 p-6 rounded-lg ${
          darkMode ? 'bg-blue-1000 hover:bg-blue-900' : 'bg-blue-100 hover:bg-blue-200'
        } transition-colors duration-300`}>
          <a 
            href="mailto:arianesouzareal@gmail.com" 
            className="flex items-center space-x-3 text-xl group"
          >
            <FaEnvelope className={`text-2xl ${
              darkMode ? 'text-blue-300' : 'text-blue-600'
            } group-hover:scale-110 transition-transform`} />
            <span className="font-medium">contact@arianesouza.com</span>
          </a>
        </div>

        {/* Social links with improved styling */}
        <div className="flex justify-center space-x-8 mt-6">
          {[
            { Icon: FaGithub, label: "GitHub" },
            { Icon: FaLinkedin, label: "LinkedIn" }
          ].map((item, index) => (
            <a
              key={index}
              href={socialLinks[index]}
              target="_blank"
              rel="noopener noreferrer"
              className={`flex flex-col items-center group`}
            >
              <div className={`p-4 rounded-full mb-2 ${
                darkMode 
                  ? 'bg-gray-800 group-hover:bg-gray-700' 
                  : 'bg-gray-100 group-hover:bg-gray-200'
              } transition-colors`}>
                <item.Icon className={`text-3xl ${
                  darkMode ? 'text-white' : 'text-blue-900'
                } group-hover:scale-110 transition-transform`} />
              </div>
              <span className="text-sm font-medium">{item.label}</span>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Contact;