import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const TestimonialsSection = () => {
  const testimonials = [
    {
        id: 1,
        name: "Bruna Kotlevski",
        title: "Project Manager",
        feedback: "A highly understanding professional regarding change requests, followed the given instructions, and completed the project efficiently!"
      },
      {
        id: 2,
        name: "Angelo Neves",
        title: "Web Developer",
        feedback: "Very professional and patient! Takes the time to understand the project's goals to deliver the best possible result. I highly recommend!"
      },
    
    {
        id: 3,
        name: " Thiago Schultz ",
        title: "ColorLab CEO",
        feedback: "Fast communication and requested items delivered on time."
      },
  
    {
      id: 4,
      name: " Paula monteiro",
      title: "Project Manager",
      feedback: "I loved your work—fast and creative!"
    },
    {
      id: 5,
      name: "Berta de Leon",
      title: "Entrepreneur",
      feedback: "Everything was perfect. Understood my requests well. "
    },
    {
      id: 6,
      name: " Storm Marketing ",
      title: "Marketing Agency",
      feedback: "Excellent professional."
    },
    {
        id: 7,
        name: "KS Studio",
        title: "Music School",
        feedback: "Excellent work and great agility in the project!"
      },
   
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleTestimonials, setVisibleTestimonials] = useState([]);
  
  const updateVisibleTestimonials = (windowWidth) => {
    let itemsToShow = 1;
    if (windowWidth >= 1280) {
      itemsToShow = 3;
    } else if (windowWidth >= 768) {
      itemsToShow = 2;
    }
    
    const visibleItems = [];
    for (let i = 0; i < itemsToShow; i++) {
      const index = (currentIndex + i) % testimonials.length;
      visibleItems.push(testimonials[index]);
    }
    
    setVisibleTestimonials(visibleItems);
  };
  
  useEffect(() => {
    const handleResize = () => {
      updateVisibleTestimonials(window.innerWidth);
    };
    
    handleResize();
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentIndex]);
  
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };
  
  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % testimonials.length
    );
  };
  
  const renderStars = () => {
    return (
      <div className="flex">
        {[...Array(5)].map((_, i) => (
          <Star 
            key={i} 
            size={18} 
            fill="#FFD700" 
            color="#FFD700" 
            className="mr-1"
          />
        ))}
      </div>
    );
  };

  return (
    <section 
    id="testimonials"
    className="w-full py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-8 mt-16 about-text">
          CLIENTS FEEDBACKS
        </h2>
          <p className="text-xl text-gray-600">From successful projects that delivered results</p>
        </div>
        
        <div className="relative">
          <div className="flex justify-between items-center mb-8">
            <button 
              onClick={handlePrev}
              className="bg-black text-white p-3 rounded-full hover:bg-gray-800 transition-colors"
              aria-label="Previous testimonial"
            >
              <ChevronLeft size={24} />
            </button>
            
            <button 
              onClick={handleNext}
              className="bg-black text-white p-3 rounded-full hover:bg-gray-800 transition-colors"
              aria-label="Next testimonial"
            >
              <ChevronRight size={24} />
            </button>
          </div>
          
          <div className="flex flex-wrap justify-center gap-8">
            {visibleTestimonials.map((testimonial) => (
              <div 
                key={testimonial.id}
                className="bg-white border-2 border-gray-100 rounded-lg p-8 shadow-lg flex flex-col w-full lg:w-1/3 md:w-1/2"
                style={{ minHeight: '320px' }}
              >
                {renderStars()}
                <p className="text-lg my-6 flex-grow">"{testimonial.feedback}"</p>
                <div>
                  <p className="font-bold text-xl">{testimonial.name}</p>
                  <p className="text-gray-600">{testimonial.title}</p>
                </div>
              </div>
            ))}
          </div>
          
          <div className="flex justify-center mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`mx-1 w-3 h-3 rounded-full ${
                  index === currentIndex ? 'bg-yellow-400' : 'bg-gray-300'
                }`}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;