import React, { useState, useEffect, useRef } from 'react';

const AnimatedHero = ({ darkMode }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [scrolled, setScrolled] = useState(false);
  const heroRef = useRef(null);
  
  // Using a non-breaking hyphen for "Front-End" to keep it together
  const titleText = "FRONTEND ENGINEER";
  const titleArray = titleText.split('');

  useEffect(() => {
    let currentIndex = 0;
    const runAnimation = () => {
      const interval = setInterval(() => {
        if (currentIndex < titleArray.length) {
          setActiveIndex(currentIndex);
          currentIndex++;
        } else {
          clearInterval(interval);
          // Reset animation after a shorter delay
          setTimeout(() => {
            setActiveIndex(-1);
            currentIndex = 0;
            // Start the next animation cycle with shorter pause
            setTimeout(runAnimation, 800);
          }, 1200);
        }
      }, 100); // Faster animation speed between letters
      return interval;
    };
    
    const interval = runAnimation();
    return () => clearInterval(interval);
  }, [titleArray.length]);

  // Add scroll event listener for background color change
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div 
      ref={heroRef}
      className={`min-h-screen flex flex-col justify-between p-8 transition-colors duration-500 ${
        darkMode 
          ? (scrolled ? 'bg-blue-1000 text-white' : 'bg-black text-white')
          : (scrolled ? 'bg-blue-100 text-blue-900' : 'bg-white text-blue-900')
      }`}
    >
      {/* Main Title with Animation and Logo */}
      <div className="flex-grow flex flex-col items-center justify-center">
        {/* Logo placement */}
        <div className="mb-8">
          <img 
            src={darkMode ? "/images/logo-white.png" : "/images/logo.png"} 
            alt="Logo" 
            className="w-24 h-24"
          />
        </div>
        
        <h1 className="text-4xl md:text-7xl tracking-tighter mb-6 flex flex-wrap justify-center whitespace-nowrap">
  {titleArray.map((letter, index) => (
    <span
      key={index}
      className={`transition-all duration-200 inline-block ${
        index === activeIndex
          ? 'transform scale-150 translate-y-3 font-bold'
          : index < activeIndex && index % 2 === 0
          ? 'transform -translate-y-3 font-normal'
          : index < activeIndex && index % 2 === 1
          ? 'transform translate-y-3 font-normal'
          : 'font-extralight'
      }`}
      style={{
        transformOrigin: 'center bottom',
        transition: index === activeIndex ? 'all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'all 0.2s ease'
      }}
    >
      {letter === ' ' ? '\u00A0' : letter}
    </span>
  ))}
</h1>
        
        {/* Center-aligned subtitle */}
        <p className="text-xl mb-16 text-center max-w-2xl mx-auto">
          Hi, I'm Ariane Souza: Frontend Engineer & UI/UX Developer.
          <br />I bridge technical functionality with polished visuals.
        </p>
      </div>
      
      {/* Footer Text */}
      <div className="grid grid-cols-1 md:grid-cols-3 text-center gap-4">
        <div className="flex flex-col items-center">
          <p className={`uppercase text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>Practical &</p>
          <p className="uppercase text-sm font-medium">functional</p>
        </div>
        <div className="flex flex-col items-center">
          <p className={`uppercase text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-600'}`} >React &</p>
          <p className="uppercase text-sm font-medium">Node.js</p>
        </div>
        <div className="flex flex-col items-center">
          <p className={`uppercase text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>User-centered</p>
          <p className="uppercase text-sm font-medium">digital experiences</p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedHero;