import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FaReact, FaJs, FaFigma, FaCss3Alt } from 'react-icons/fa';
import { SiHtml5, SiNodedotjs, SiRedux, SiTypescript } from 'react-icons/si';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const About = ({ darkMode }) => {
  const [activeTab, setActiveTab] = useState('skills');
  const sectionRef = useRef(null);
  const experienceContainerRef = useRef(null);
  
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });
  
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const yOffset = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [100, 0, 0, 100]);

  const skills = [
    { name: 'React', icon: <FaReact />, level: 90 },
    { name: 'JavaScript', icon: <FaJs />, level: 85 },
    { name: 'TypeScript', icon: <SiTypescript />, level: 80 },
    { name: 'HTML', icon: <SiHtml5 />, level: 85 },
    { name: 'Node.js', icon: <SiNodedotjs />, level: 75 },
    { name: 'Figma', icon: <FaFigma />, level: 90 },
    { name: 'CSS/SCSS', icon: <FaCss3Alt />, level: 88 },
    { name: 'MySQL', icon: <FaCss3Alt />, level: 70 },
    { name: 'RestulAPI', icon: <FaCss3Alt />, level: 85 },
  ];

  const experiences = [
    { 
      year: '2022-Present', 
      title: 'React & Frontend Developer ',
      description: 'Architecting scalable React applications with a focus on performance, interactivity, and modern design patterns.'
    },
    { 
      year: '2019-Present', 
      title: 'UX/UI Designer & Frontend Developer',
      description: 'Designing and developing seamless user experiences, bridging the gap between aesthetics and functionality with precise implementation.'
    },
    { 
      year: '2015-2023', 
      title: 'Web & Graphic Design',
      description: 'Customizing and enhancing web templates, optimizing UI animations, and refining user interactions for an engaging digital presence.'
    },
  ];
  
  // GSAP scroll animation
  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        scrub: 1,
        trigger: sectionRef.current,
        start: "top 90%",
        end: "bottom 30%",
      },
    });
    
    // Additional dynamic layout animation for tab switching
    if (activeTab === 'experience' && experienceContainerRef.current) {
      const items = experienceContainerRef.current.querySelectorAll('.experience-item');
      gsap.fromTo(items, 
        { opacity: 0, y: 20 },
        { 
          opacity: 1, 
          y: 0, 
          duration: 0.6, 
          stagger: 0.15,
          ease: "power3.out"
        }
      );
    }
    
    return () => {
      if (timeline.scrollTrigger) {
        timeline.scrollTrigger.kill();
      }
    };
  }, [activeTab]);

  // Parallax hover effect for experience cards - fixed to avoid disappearing
  const handleMouseMove = (e, element) => {
    const rect = element.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const xPercent = x / rect.width - 0.5;
    const yPercent = y / rect.height - 0.5;
    
    // Reduced rotation amount to prevent extreme angles
    gsap.to(element, {
      rotationY: xPercent * 3,
      rotationX: yPercent * -3,
      transformPerspective: 1000,
      duration: 0.4,
      ease: "power1.out",
      force3D: true,
      // Important: keep other properties intact
      opacity: 1
    });
  };
  
  const handleMouseLeave = (element) => {
    gsap.to(element, {
      rotationY: 0,
      rotationX: 0,
      duration: 0.6,
      ease: "elastic.out(1, 0.3)",
      force3D: true,
      opacity: 1
    });
  };

  return (
    <section 
      id="about" 
      ref={sectionRef}
      className={`py-24 ${darkMode ? 'bg-gray-900' : 'bg-gray-50'} relative overflow-hidden`}
    >
      
      <div className="container mx-auto px-6 relative z-10">
        {/* Headline Section with enhanced typography and entrance animation */}
        <motion.div 
          
        >
         <motion.h2 
                                   className="text-6xl md:text-8xl font-black mb-3 tracking-tight tracking-wider text-center"
                                   initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
                                   whileInView={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
                                   viewport={{ once: true }}
                                   transition={{ duration: 1, ease: "easeOut" }}
                                 >
                                   <div className={`${darkMode ? 'text-white' : 'text-gray-900'} tracking-[0.2em] font-light mb-0`}>
                                       PROFILE
                                       </div>
                       
                                   <span className="text-blue-500 ml-4"></span>
                                 </motion.h2>
         
          <motion.p 
            className={`text-xl md:text-2xl max-w-xl ${darkMode ? 'text-gray-400' : 'text-gray-600'} font-light`}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.7 }}
          >
         
          </motion.p>
        </motion.div>
        
        {/* Main content section - Webflow inspired layout with improved grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 relative">
          {/* Main Bio section - clean, with subtle entrance animations */}
          <motion.div 
            className="lg:col-span-6 lg:col-start-1"
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7 }}
          >
            <div className="pr-6">
              <motion.h3 
                className="text-3xl font-bold mb-8 text-blue-500"
                initial={{ x: -20, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                My Journey
              </motion.h3>
              
              <motion.p 
                className="text-xl mb-8 leading-relaxed"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.1 }}
              >
                <span className="font-semibold text-2xl block mb-4">
                  I build
                  <motion.span 
                    className={`${darkMode ? 'text-blue-400' : 'text-blue-600'} mx-2`}
                    initial={{ opacity: 0.5 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ 
                      repeat: Infinity, 
                      repeatType: "reverse", 
                      duration: 2
                    }}
                  >
                    scalable
                  </motion.span> 
                  digital experiences.
                </span>
                Since 2015, I have worked with HTML, CSS, JavaScript, and Graphic Design, starting with customizing 
                and improving web templates. Over time, I delved into UI enhancements, animations, and interactive features, 
                strengthening my understanding of user experience and frontend performance.
              </motion.p>
              
              <div className="flex flex-col md:flex-row gap-6 mb-8">
                <motion.div 
                  className={`flex-1 p-5 rounded-xl ${darkMode ? 'bg-gray-800/50' : 'bg-gray-100/80'} hover:shadow-lg transition-all duration-300`}
                  whileHover={{ y: -5 }}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <h4 className="text-lg font-bold mb-2">
                    <motion.span 
                      className="text-blue-500 text-2xl mr-2"
                      initial={{ scale: 1 }}
                      whileInView={{ 
                        scale: [1, 1.2, 1],
                        transition: { 
                          duration: 1.5, 
                          times: [0, 0.5, 1],
                          ease: "easeInOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                  
                    What I do<br /> <br /> 

                    </motion.span>
                    Frontend Development → Crafting performant and responsive web applications.<br /> <br />
                    UX/UI Design → Designing intuitive interfaces with a user-first approach.<br /><br />
                    Optimization & Performance → Enhancing load speed, accessibility, and user flow.
                  </h4>
                  <p className="text-sm">
                  
                  </p>
                </motion.div>
                <motion.div 
                  className={`flex-1 p-5 rounded-xl ${darkMode ? 'bg-gray-800/50' : 'bg-gray-100/80'} hover:shadow-lg transition-all duration-300`}
                  whileHover={{ y: -5 }}
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <h4 className="text-lg font-bold mb-2">
                    <motion.span 
                      className="text-blue-500 text-2xl mr-2"
                      initial={{ scale: 1 }}
                      whileInView={{ 
                        scale: [1, 1.2, 1],
                        transition: { 
                          duration: 1.5, 
                          delay: 0.2,
                          times: [0, 0.5, 1],
                          ease: "easeInOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                      Main Frameworks <br /><br /> 
                    </motion.span>
                    React <br />  <br /> 
                    JavaScript <br /> <br /> 
                     Node.js <br /> <br /> 
                     Next.js 
                    
                    
                  </h4>
                  <p className="text-sm">
                 
                  </p>
                </motion.div>
              </div>
              
              <motion.p 
                className="text-lg mb-8"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
              In 2023, I shifted my focus to building full-scale React applications,
               working with component-based architecture, state management, and modern UI frameworks.
               I strive to write clean, maintainable code while prioritizing usability, accessibility, 
               and performance to enhance user interactions.
              </motion.p>
              
              {/* Philosophy section - with subtle reveal animation */}
              <motion.div 
                className={`mt-12 pt-6 border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.7, delay: 0.5 }}
              >
                <h3 className="text-2xl font-bold mb-4">My Philosophy</h3>
                <p className="text-lg">
                  I believe in <span className="font-semibold">intuitive design</span> paired with 
                  <span className="font-semibold"> clean code</span>. Whether optimizing app performance 
                  or refining complex user flows, my approach always centers on creating 
                  <motion.span 
                    className={`${darkMode ? 'text-blue-400' : 'text-blue-600'} font-bold`}
                    whileInView={{
                      color: [
                        darkMode ? '#60a5fa' : '#2563eb', 
                        darkMode ? '#818cf8' : '#4f46e5', 
                        darkMode ? '#60a5fa' : '#2563eb'
                      ]
                    }}
                    transition={{ duration: 3, repeat: Infinity }}
                  > impactful user experiences</motion.span>.
                </p>
              </motion.div>
            </div>
          </motion.div>
         
          {/* Skills & Experience section - Right side */}
          <motion.div 
            className="lg:col-span-5 lg:col-start-8"
            initial={{ opacity: 0, y: 60 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <motion.div 
              className={`p-8 rounded-2xl ${darkMode ? 'bg-gray-800/80' : 'bg-white'} shadow-xl`}
              whileHover={{ boxShadow: darkMode ? "0 25px 50px -12px rgba(0, 0, 0, 0.7)" : "0 25px 50px -12px rgba(0, 0, 0, 0.25)" }}
              transition={{ duration: 0.3 }}
            >
              {/* Tab navigation with active indicator animation */}
              <div className="flex gap-4 mb-8 relative">
                <motion.button 
                  onClick={() => setActiveTab('skills')}
                  className={`px-6 py-3 rounded-full font-medium text-sm transition-all relative z-10 ${
                    activeTab === 'skills' 
                      ? `${darkMode ? 'text-white' : 'text-white'}` 
                      : `${darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`
                  }`}
                  whileHover={{ scale: activeTab !== 'skills' ? 1.05 : 1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  SKILLS
                </motion.button>
                <motion.button 
                  onClick={() => setActiveTab('experience')}
                  className={`px-6 py-3 rounded-full font-medium text-sm transition-all relative z-10 ${
                    activeTab === 'experience' 
                      ? `${darkMode ? 'text-white' : 'text-white'}` 
                      : `${darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`
                  }`}
                  whileHover={{ scale: activeTab !== 'experience' ? 1.05 : 1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  EXPERIENCE
                </motion.button>
                
                {/* Animated background for active tab */}
                <motion.div
                  className="absolute inset-0 rounded-full bg-blue-500 z-0"
                  initial={false}
                  animate={{
                    x: activeTab === 'skills' ? 0 : '100%',
                    width: activeTab === 'skills' ? '110px' : '140px' // Adjust based on button widths
                  }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                ></motion.div>
              </div>
              
              {/* Skills tab with staggered animations */}
              {activeTab === 'skills' && (
                <motion.div 
                  className="space-y-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {skills.map((skill, index) => (
                    <motion.div 
                      key={skill.name}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className="mb-6"
                    >
                      <div className="flex items-center mb-3">
                        <motion.div 
                          className={`text-2xl p-2 rounded-lg mr-3 ${darkMode ? 'bg-gray-700 text-blue-400' : 'bg-blue-100 text-blue-600'}`}
                          whileHover={{ scale: 1.1, rotate: 10 }}
                          transition={{ type: "spring", stiffness: 300, damping: 10 }}
                        >
                          {skill.icon}
                        </motion.div>
                        <span className="text-lg font-medium">{skill.name}</span>
                        <span className={`ml-auto text-sm font-bold ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                          {skill.level}%
                        </span>
                      </div>
                      <div className={`w-full h-3 bg-gray-200 rounded-full overflow-hidden ${darkMode ? 'bg-gray-700' : ''}`}>
                        <motion.div 
                          className="h-full rounded-full bg-gradient-to-r from-blue-500 to-indigo-600"
                          initial={{ width: 0 }}
                          animate={{ width: `${skill.level}%` }}
                          transition={{ duration: 1, delay: 0.2 + index * 0.1, ease: "easeOut" }}
                        ></motion.div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              )}
              
              {/* Experience tab with evenly distributed items and 3D hover effects */}
              {activeTab === 'experience' && (
                <motion.div 
                  className="flex flex-col justify-between h-full space-y-4"
                  ref={experienceContainerRef}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{ minHeight: "420px" }} // Ensure consistent height with skills tab
                >
                  {experiences.map((exp, index) => (
                    <motion.div 
                      key={exp.year}
                      className={`experience-item relative p-6 rounded-xl transform transition-all duration-300 ${
                        darkMode ? 'bg-gray-700/50 hover:bg-gray-700' : 'bg-gray-50 hover:bg-gray-100'
                      } flex-1 flex flex-col backface-visibility-hidden`} // Added backface-visibility hidden
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4, delay: index * 0.15 }}
                      whileHover={{ 
                        y: -5,
                        boxShadow: darkMode ? "0 10px 25px -5px rgba(0, 0, 0, 0.3)" : "0 10px 25px -5px rgba(0, 0, 0, 0.1)" 
                      }}
                      onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}
                      onMouseLeave={(e) => handleMouseLeave(e.currentTarget)}
                      style={{ 
                        transformStyle: "preserve-3d", // Critical for 3D effect
                        perspective: "1000px"
                      }}
                    >
                      <motion.span 
                        className="absolute -left-3 top-1/2 transform -translate-y-1/2 w-6 h-6 rounded-full bg-blue-500 border-4 border-white dark:border-gray-800 flex items-center justify-center"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: 0.3 + index * 0.15, duration: 0.4, type: "spring" }}
                      >
                        <span className="h-2 w-2 rounded-full bg-white"></span>
                      </motion.span>
                      
                      <div className={`font-black text-3xl mb-1 ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                        {exp.year}
                      </div>
                      <div className="text-xl font-bold mb-2">{exp.title}</div>
                      <div className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {exp.description}
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;