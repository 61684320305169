import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate, useLocation } from 'react-router-dom';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Sample project data 
const projects = [
  {
    id: 3,
    name: 'UI Dashboard',
    category: 'VILA VELHA INSURANCE BROKER',
    image: '/images/vl/new-design-thumb.png',
    
  },
  {
    id: 2,
    name: 'Landing Page',
    category: 'BAHIA VAN TOUR',
    image: '/images/vantour/d-van-tour-thumb.png',
    liveLink: 'https://www.figma.com/proto/uLWAsONKTcmhrZC0z0zvdS/Ariane-Souza-Projetos?node-id=929-24&t=ow9rhSQmMhTRQLFp-1' 
    
  },
  {
    id: 1,
    name: 'Web App ',
    category: 'KINKOA',
    image: '/images/kinkoa/new-thumb-k.png',
  
  },


  {
    id: 4,
    name: 'Grid Portfolio UI',
    category: 'ARIANE GAROI',
    image: '/images/gridwebsite/gridfolio-thumb.png',
    liveLink: 'https://www.arianesouza.com' // Another project with a live link
  },
 
];

const PortfolioSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const projectRefs = useRef([]);

  // Function to handle navigation to project page
  const handleViewProject = (projectId, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    navigate(`/project/${projectId}`, { 
      state: { from: location.pathname } 
    });
  };

  useEffect(() => {
    // Create a GSAP context to easily kill all animations later
    const ctx = gsap.context(() => {
      // Using batch to optimize performance for multiple triggers
      gsap.utils.toArray(projectRefs.current).forEach((projectEl, index) => {
        // Create a timeline for each project card
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 80%",   // Adjusted for better performance and less overlap
            end: "top 30%",     // Adjusted for better performance and less overlap
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        // Animate the card from laying down to standing up
        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.85
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          duration: 0.4,
          ease: "power2.out"
        });
      });
    });

    // Cleanup function
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-8 mt-16 about-text">
          UX PROJECTS
        </h2>
        <div className="grid grid-cols-1 gap-12" style={{perspective: "1500px"}}>
          {projects.map((project, index) => (
            <div
              key={index}
              ref={el => projectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-4 border-black"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity"  // Optimized for GPU rendering
              }}
            >
              <div className="relative w-full h-[90vh]">
                <img
                  src={project.image}
                  alt={project.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60"></div>
                <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
                    <div className="mb-4 sm:mb-0">
                      <h3 className="text-3xl font-bold mb-2">{project.name}</h3>
                      {project.liveLink && (
                        <a
                          href={project.liveLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm hover:text-gray-300 cursor-pointer group-hover:bg-white"
                        >
                          <span className="relative cursor-pointer z-10 group-hover:text-black flex items-center">
                          See Live Website
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-4 w-4 inline-block ml-2 transition-all duration-300 group-hover:text-black group-hover:translate-x-2 cursor-pointer"
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M14 5l7 7m0 0l-7 7m7-7H3" 
                            />
                          </svg>
                          </span>
                        </a>
                      )}
                    </div>
                                      
                    <div className="flex flex-col items-center w-full sm:w-auto">
  <p className="text-sm mb-1 text-center">{project.category}</p>
  <button
    onClick={(e) => handleViewProject(project.id, e)}
    className="group relative overflow-hidden rounded-full px-4 py-2 text-white text-sm transition-all duration-300 hover:bg-white block text-left cursor-pointer"
  >
    <span className="relative cursor-pointer z-10 group-hover:text-black flex items-center">
      See Analysis → 
    </span>
    <span 
      className="absolute cursor-pointer inset-0 bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
    ></span>
  </button>
</div>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;