import React, { useState, useEffect } from 'react';
import { X, Maximize, Minimize } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Skills  from "./Skills"; 
import About  from "./About"; 

const CVPage = ({ onClose, darkMode }) => {
  const [fullscreenMode, setFullscreenMode] = useState(false);

  useEffect(() => {
    // Disable scrolling on the body when the modal is open
    document.body.style.overflow = 'hidden';
    
    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    },
    exit: { 
      opacity: 0, 
      y: -20,
      transition: {
        duration: 0.4
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div 
        className={`fixed inset-0 z-50 overflow-y-auto overscroll-contain ${
          darkMode 
            ? 'bg-gray-900 text-white' 
            : 'bg-white text-gray-800'
        }`}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        {/* Top navigation bar */}
        <div className={`sticky top-0 z-10 ${
          darkMode 
            ? 'bg-gray-800 bg-opacity-90 border-gray-700' 
            : 'bg-white bg-opacity-90 border-gray-100'
        } backdrop-blur-sm border-b`}>
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex items-center space-x-4">
                
              </div>
              <button 
                onClick={onClose}
                className={`p-2 rounded-full ${
                  darkMode 
                    ? 'hover:bg-gray-700' 
                    : 'hover:bg-gray-100'
                } transition-colors`}
                aria-label="Close CV"
              >
                <X className={`w-5 h-5 ${darkMode ? 'text-white' : 'text-gray-600'}`} />
              </button>
            </div>
          </div>
        </div>

        {/* CV Content */}
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Hero Section */}
          <div className={`flex items-center mb-16 ${
            darkMode 
              ? 'bg-gray-800' 
              : 'bg-gray-100'
          } p-8 rounded-lg`}>
            <div className="w-32 h-32 rounded-full overflow-hidden mr-8">
              <img 
                src={darkMode ? "/images/logo-white.png" : "/images/logo.png"} 
                alt="Blake Alexander" 
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h1 className={`text-4xl font-light ${
                darkMode 
                  ? 'text-white' 
                  : 'text-gray-800'
              } mb-2`}>Ariane Souza</h1>
              <p className={`text-xl ${
                darkMode 
                  ? 'text-gray-400' 
                  : 'text-gray-600'
              }`}>Frontend Engineer & UX/UI Developer </p>
            </div>
          </div>

          {/* Skills Section */}
         <About darkMode={darkMode} />

          {/* Experience Section */}
       <Skills darkMode={darkMode} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CVPage;