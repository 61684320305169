import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, useScroll, useTransform } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = ({ darkMode = true }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  
  // Create refs
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const linesRef = useRef(null);

  const services = [
    {
      title: 'Interface Design',
      description: 'I create visually compelling and intuitive user interfaces that enhance brand identity and user engagement'
    },
    {
      title: 'Prototyping & Wireframing',
      description: 'I build interactive prototypes and wireframes to test and validate design concepts before development.'
    },
    {
      title: 'User Research',
      description: 'I Analyze user behavior, market trends, and pain points to create data-driven design solutions.'
    },
    {
      title: 'Usability Testing & Optimization',
      description: 'I Conduct tests and gathering feedback to refine interactions, improve accessibility, and enhance user satisfaction.'
    },
    {
      title: 'Figma to HTML/CSS',
      description: 'I transform high-fidelity Figma designs into pixel-perfect, responsive web pages using clean, maintainable code.'
    },
    {
      title: 'React Frontend Development',
      description: ' I Buil dynamic and interactive web applications with React, ensuring seamless user experiences and optimized performance.'
    }

  ];

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });
    
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const yOffset = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [100, 0, 0, 100]);

  useEffect(() => {
    const lines = linesRef.current;
    
    // Set up intersection observer for scroll animations
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    // GSAP Animation
    if (lines && containerRef.current) {
      gsap.to(lines, {
        opacity: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top center',
          end: 'bottom center',
          scrub: true,
          markers: false
        }
      });
    }
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      setIsVisible(false);
    };
  }, []);

  return (
    <div 
      id="services"  
      ref={sectionRef} 
      className={`py-20 w-full overflow-x-hidden ${darkMode ? 'bg-white text-white' : 'bg-white text-gray-800'}`}
    >
      <div 
        ref={containerRef}
        className="w-full overflow-x-hidden"
      >
        {/* Title and Subtitle - Now centered */}
        <motion.div 
          className="mb-12 text-center relative px-4"
          style={{ opacity, y: yOffset }}
        >
          <motion.h2 
            className="text-6xl md:text-8xl font-black mb-3 tracking-tight tracking-wider text-center"
            initial={{ clipPath: "polygon(0 0, 0 0, 0 100%, 0 100%)" }}
            whileInView={{ clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
 

      <h2 className="text-5xl md:text-7xl text-center font-bold mb-8 mt-16 about-text">
          WHAT I DO
        </h2>
          </motion.h2>
        </motion.div>
        
        <div className="w-full overflow-x-hidden" ref={linesRef}>
          {services.map((service, index) => (
            <div 
              key={index}
              className="relative group w-full"
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {/* Desktop View (Hover Effect) */}
              <div className="hidden md:block">
                <div 
                  className={`
                    flex items-center w-full relative
                    ${darkMode ? 'border-white/20' : 'border-black/20'}
                    py-8
                    transition-all duration-300 ease-in-out
                    ${hoveredItem === index 
                      ? (darkMode 
                        ? 'bg-yellow-100 text-black' 
                        : 'bg-[#f4cd49] text-black')
                      : (darkMode 
                        ? 'bg-gray-900 text-white' 
                        : 'bg-white text-black')}
                  `}
                >
                  <div className="text-5xl font-regular pl-16  flex-grow px-4 relative z-10">
                    {service.title}
                  </div>
                  <div 
                    className={`
                      absolute right-0 top-0 bottom-0 flex items-center px-4 
                      transition-all duration-300 ease-in-out
                      ${hoveredItem === index 
                        ? 'w-1/2 opacity-100' 
                        : 'w-0 opacity-0'}
                      overflow-hidden
                    `}
                  >
                    {service.description}
                  </div>
                </div>
              </div>

              {/* Mobile View (Always Visible Description) */}
              <div className="md:hidden">
                <div className={`
                  w-full
                  border-b 
                  ${darkMode ? 'border-white/20' : 'border-black/20'} 
                  pb-6 px-4
                `}>
                  <div className="text-4xl font-bold mb-4">
                    {service.title}
                  </div>
                  <div className={`${darkMode ? 'text-white/80' : 'text-black/80'}`}>
                    {service.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;